.theme-selector {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.theme-selector-item {
  margin :0 15px;
}

.selectboxDropdown .dx-list-item-content {
  display: inline-block;
  font-size: 16px;
  padding: 0px;
}

.custom-item {
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dx-texteditor.dx-editor-outlined .dx-texteditor-input.theme-textbox {
  font-size: 14px;
  padding: 0px;
}

.theme-text {
  font-size: 14px;
  height: 17px;
}