@font-face {
  font-family: "Dyslexia";
  src: url("./assets/fonts/OpenDyslexic3-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Tabs navs */
.dx-tabpanel-tabs .dx-tabs .dx-item.dx-tab.dx-tab-selected {
  background: #141b4d;
}

.dx-tabpanel-tabs .dx-tabs .dx-item:hover {
  background: #545da385;
}

.dx-tabpanel-tabs .dx-tabs .dx-item.dx-tab.dx-tab-selected * {
  color: white
}

/* Lang Switcher */
.no-caret::after {
  display: none !important;
}

.dx-scrollable-container {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}